import React, { useState, useEffect } from 'react';
import { Phone, PhoneOff, Clock, Signal, AlertCircle, Cpu } from 'lucide-react';

const RealTimeCallMonitor = ({ clientId, authToken }) => {
  const [metrics, setMetrics] = useState({
    health: {
      active_calls: 0,
      queue_length: 0,
      system_status: 'operational'
    },
    performance: {},
    system: {},
    alerts: []
  });
  const [connectionStatus, setConnectionStatus] = useState('disconnected');
  const [error, setError] = useState(null);

  useEffect(() => {
    let ws;
    let reconnectTimeout;
    let reconnectAttempts = 0;
    const MAX_RECONNECT_ATTEMPTS = 5;

    const connectWebSocket = () => {
      const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const wsHost = process.env.NODE_ENV === 'development' 
        ? 'localhost:8001'
        : window.location.host;
      
      ws = new WebSocket(`${wsProtocol}//${wsHost}/ws/voice/${clientId}`);

      ws.onopen = () => {
        setConnectionStatus('connected');
        setError(null);
        reconnectAttempts = 0;

        // Subscribe to all metrics
        ws.send(JSON.stringify({
          type: 'subscribe',
          metrics: ['health', 'performance', 'system', 'alerts'],
          interval: 1000
        }));
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.metrics) {
            setMetrics(data.metrics);
          }
        } catch (err) {
          console.error('Error parsing WebSocket message:', err);
        }
      };

      ws.onclose = () => {
        setConnectionStatus('disconnected');
        if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
          reconnectAttempts++;
          const timeout = Math.min(1000 * Math.pow(2, reconnectAttempts), 30000);
          reconnectTimeout = setTimeout(connectWebSocket, timeout);
        } else {
          setError('Maximum reconnection attempts reached');
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        setConnectionStatus('error');
      };
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
    };
  }, [clientId, authToken]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'operational': return 'text-green-500';
      case 'degraded': return 'text-yellow-500';
      case 'error': return 'text-red-500';
      default: return 'text-gray-500';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Real-Time Monitoring</h2>
        <div className="flex items-center gap-2">
          <Signal className={`w-5 h-5 ${
            connectionStatus === 'connected' ? 'text-green-500' :
            connectionStatus === 'error' ? 'text-red-500' :
            'text-yellow-500'
          }`} />
          <span className="text-sm text-gray-600">
            {connectionStatus === 'connected' ? 'Connected' :
             connectionStatus === 'error' ? 'Error' :
             'Reconnecting...'}
          </span>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md text-red-700 text-sm">
          {error}
        </div>
      )}

      {/* Metrics Grid */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <MetricCard
          label="Active Calls"
          value={metrics.health.active_calls}
          icon={<Phone className="w-5 h-5 text-blue-500" />}
        />
        <MetricCard
          label="Queue Length"
          value={metrics.health.queue_length}
          icon={<Clock className="w-5 h-5 text-yellow-500" />}
        />
        <MetricCard
          label="System Status"
          value={metrics.health.system_status}
          icon={<Cpu className={`w-5 h-5 ${getStatusColor(metrics.health.system_status)}`} />}
          isStatus
        />
        <MetricCard
          label="Active Alerts"
          value={metrics.alerts?.length || 0}
          icon={<AlertCircle className="w-5 h-5 text-red-500" />}
        />
      </div>

      {/* Recent Alerts */}
      {metrics.alerts?.length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3">Recent Alerts</h3>
          <div className="space-y-2">
            {metrics.alerts.map((alert, index) => (
              <div key={index} className="p-3 bg-red-50 border border-red-200 rounded-md text-red-700 text-sm">
                {alert.message}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* System Metrics */}
      {metrics.system && Object.keys(metrics.system).length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3">System Metrics</h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {Object.entries(metrics.system).map(([key, value]) => (
              <div key={key} className="p-4 bg-gray-50 rounded-lg">
                <p className="text-sm text-gray-500 capitalize">{key.replace('_', ' ')}</p>
                <p className="text-lg font-semibold text-gray-900">{value}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const MetricCard = ({ label, value, icon, isStatus }) => {
  const renderValue = () => {
    if (isStatus) {
      return (
        <span className={`capitalize ${
          value === 'operational' ? 'text-green-600' :
          value === 'degraded' ? 'text-yellow-600' :
          'text-red-600'
        }`}>
          {value}
        </span>
      );
    }
    return value;
  };

  return (
    <div className="bg-gray-50 rounded-lg p-4">
      <div className="flex items-center justify-between mb-2">
        {icon}
        <span className="text-sm text-gray-600">{label}</span>
      </div>
      <p className="text-2xl font-bold text-gray-900">
        {renderValue()}
      </p>
    </div>
  );
};

export default RealTimeCallMonitor;
