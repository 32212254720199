import React, { useState, useEffect } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Loader, Phone, Clock, AlertCircle, BarChart2 } from 'lucide-react';

const AnalyticsDashboard = ({ clientId }) => {
  const [metrics, setMetrics] = useState({
    metrics: {
      total_calls: 0,
      unique_callers: 0,
      avg_duration: 0,
      success_rate: 0,
      error_count: 0,
      voice_quality: {
        clarity: 0,
        stability: 0,
        naturalness: 0
      }
    },
    hourly_distribution: [],
    recent_calls: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState('week');

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `/api/analytics/metrics/${clientId}?range=${dateRange}`,
          {
            credentials: 'include'
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch metrics');
        }

        const data = await response.json();
        setMetrics(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [clientId, dateRange]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-md p-4 text-red-700">
        Error loading analytics: {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Date Range Selector */}
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => setDateRange('week')}
          className={`px-4 py-2 rounded-md ${
            dateRange === 'week' ? 'bg-blue-500 text-white' : 'bg-gray-100'
          }`}
        >
          Week
        </button>
        <button
          onClick={() => setDateRange('month')}
          className={`px-4 py-2 rounded-md ${
            dateRange === 'month' ? 'bg-blue-500 text-white' : 'bg-gray-100'
          }`}
        >
          Month
        </button>
        <button
          onClick={() => setDateRange('year')}
          className={`px-4 py-2 rounded-md ${
            dateRange === 'year' ? 'bg-blue-500 text-white' : 'bg-gray-100'
          }`}
        >
          Year
        </button>
      </div>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between mb-2">
            <Phone className="w-5 h-5 text-blue-500" />
            <span className="text-gray-500 text-sm">Total Calls</span>
          </div>
          <p className="text-3xl font-bold text-blue-600">
            {metrics.metrics.total_calls}
          </p>
        </div>

        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between mb-2">
            <BarChart2 className="w-5 h-5 text-green-500" />
            <span className="text-gray-500 text-sm">Success Rate</span>
          </div>
          <p className="text-3xl font-bold text-green-600">
            {metrics.metrics.success_rate}%
          </p>
        </div>

        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between mb-2">
            <Clock className="w-5 h-5 text-purple-500" />
            <span className="text-gray-500 text-sm">Avg Duration</span>
          </div>
          <p className="text-3xl font-bold text-purple-600">
            {Math.floor(metrics.metrics.avg_duration / 60)}:
            {String(Math.floor(metrics.metrics.avg_duration % 60)).padStart(2, '0')}
          </p>
        </div>

        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between mb-2">
            <AlertCircle className="w-5 h-5 text-red-500" />
            <span className="text-gray-500 text-sm">Errors</span>
          </div>
          <p className="text-3xl font-bold text-red-600">
            {metrics.metrics.error_count}
          </p>
        </div>
      </div>

      {/* Voice Quality Metrics */}
      <div className="bg-white rounded-lg shadow p-4">
        <h3 className="text-lg font-semibold mb-4">Voice Quality</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className="text-center">
            <p className="text-sm text-gray-500">Clarity</p>
            <p className="text-2xl font-bold text-blue-600">
              {metrics.metrics.voice_quality.clarity}%
            </p>
          </div>
          <div className="text-center">
            <p className="text-sm text-gray-500">Stability</p>
            <p className="text-2xl font-bold text-blue-600">
              {metrics.metrics.voice_quality.stability}%
            </p>
          </div>
          <div className="text-center">
            <p className="text-sm text-gray-500">Naturalness</p>
            <p className="text-2xl font-bold text-blue-600">
              {metrics.metrics.voice_quality.naturalness}%
            </p>
          </div>
        </div>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Hourly Distribution Chart */}
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold mb-4">Hourly Distribution</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={metrics.hourly_distribution}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="hour"
                  tickFormatter={(hour) => `${hour}:00`}
                />
                <YAxis />
                <Tooltip 
                  labelFormatter={(hour) => `${hour}:00`}
                  formatter={(value, name) => [
                    name === 'success_rate' ? `${value}%` : value,
                    name === 'success_rate' ? 'Success Rate' : 'Calls'
                  ]}
                />
                <Legend />
                <Bar dataKey="calls" fill="#3B82F6" name="Calls" />
                <Bar dataKey="success_rate" fill="#10B981" name="Success Rate" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Duration Distribution Chart */}
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold mb-4">Duration by Hour</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={metrics.hourly_distribution}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="hour"
                  tickFormatter={(hour) => `${hour}:00`}
                />
                <YAxis />
                <Tooltip 
                  labelFormatter={(hour) => `${hour}:00`}
                  formatter={(value) => [
                    `${Math.floor(value / 60)}:${String(Math.floor(value % 60)).padStart(2, '0')}`,
                    'Average Duration'
                  ]}
                />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="avg_duration" 
                  stroke="#8B5CF6" 
                  name="Average Duration" 
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Recent Calls Table */}
      <div className="bg-white rounded-lg shadow p-4">
        <h3 className="text-lg font-semibold mb-4">Recent Calls</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Time
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Caller
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Duration
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Voice Quality
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {metrics.recent_calls.map((call) => (
                <tr key={call.call_sid}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {new Date(call.started_at).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {call.caller_number}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {Math.floor(call.duration / 60)}:
                    {String(Math.floor(call.duration % 60)).padStart(2, '0')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {call.voice_metrics?.clarity 
                      ? `${(call.voice_metrics.clarity * 100).toFixed(1)}%`
                      : 'N/A'
                    }
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      call.call_status === 'completed'
                        ? 'bg-green-100 text-green-800'
                        : call.error
                        ? 'bg-red-100 text-red-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {call.call_status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
