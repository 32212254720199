import React, { useState, useEffect } from 'react';

function Settings() {
  const [settings, setSettings] = useState({
    notifications: false,
    darkMode: false,
    language: 'en'
  });
  const [saveStatus, setSaveStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // TODO: Replace with actual user authentication
  const userId = 1;

  // Load settings when component mounts
  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await fetch(`/api/settings/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch settings');
      }
      const data = await response.json();
      setSettings(data);
      setLoading(false);
    } catch (err) {
      setError('Failed to load settings');
      setLoading(false);
      console.error('Error fetching settings:', err);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaveStatus('Saving...');
    
    try {
      const response = await fetch(`/api/settings/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(settings)
      });

      if (!response.ok) {
        throw new Error('Failed to save settings');
      }

      const updatedSettings = await response.json();
      setSettings(updatedSettings);
      setSaveStatus('Settings saved successfully!');
      
      // Apply dark mode if changed
      if (updatedSettings.darkMode) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
      
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (err) {
      setSaveStatus('Error saving settings');
      console.error('Error saving settings:', err);
    }
  };

  if (loading) return <div>Loading settings...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Settings</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Preferences</h3>
          
          <div style={styles.formGroup}>
            <label style={styles.checkboxLabel}>
              <input
                type="checkbox"
                name="notifications"
                checked={settings.notifications}
                onChange={handleChange}
                style={styles.checkbox}
              />
              Enable Notifications
            </label>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.checkboxLabel}>
              <input
                type="checkbox"
                name="darkMode"
                checked={settings.darkMode}
                onChange={handleChange}
                style={styles.checkbox}
              />
              Dark Mode
            </label>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>
              Language:
              <select 
                name="language" 
                value={settings.language} 
                onChange={handleChange}
                style={styles.select}
              >
                <option value="en">English</option>
                <option value="es">Spanish</option>
                <option value="fr">French</option>
              </select>
            </label>
          </div>
        </div>

        <div style={styles.buttonContainer}>
          <button type="submit" style={styles.button}>
            Save Settings
          </button>
          {saveStatus && (
            <span style={styles.saveStatus}>{saveStatus}</span>
          )}
        </div>
      </form>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
  },
  title: {
    color: '#333',
    marginBottom: '30px',
    textAlign: 'left',
  },
  form: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  section: {
    marginBottom: '30px',
  },
  sectionTitle: {
    fontSize: '18px',
    color: '#666',
    marginBottom: '15px',
    borderBottom: '1px solid #eee',
    paddingBottom: '10px',
  },
  formGroup: {
    marginBottom: '15px',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  checkbox: {
    marginRight: '10px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#666',
  },
  select: {
    width: '100%',
    padding: '8px',
    marginTop: '5px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  button: {
    backgroundColor: '#0066cc',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  saveStatus: {
    color: '#666',
    fontSize: '14px',
  }
};

export default Settings;
