import { useEffect, useState, useCallback } from 'react';
import { Phone, PhoneOff, AlertCircle, Signal, BarChart2 } from 'lucide-react';

const ActiveCallsMonitor = ({ clientId, authToken }) => {
    const [activeCalls, setActiveCalls] = useState([]);
    const [connectionStatus, setConnectionStatus] = useState('disconnected');
    const [error, setError] = useState(null);
    const [metrics, setMetrics] = useState({
        total_active: 0,
        total_completed: 0,
        average_duration: 0,
        success_rate: 0
    });

    const getWebSocketUrl = (clientId) => {
        const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        const isDevelopment = process.env.NODE_ENV === 'development';

        if (isDevelopment) {
            return `ws://localhost:8000/ws/voice/${clientId}`;
        }

        return `${wsProtocol}//${window.location.host}/ws/voice/${clientId}`;
    };

    const handleMessage = useCallback((event) => {
        try {
            const message = JSON.parse(event.data);
            switch (message.type) {
                case 'calls_update':
                    if (message.payload.calls) {
                        setActiveCalls(message.payload.calls);
                    }
                    if (message.payload.metrics) {
                        setMetrics(message.payload.metrics);
                    }
                    break;
                case 'error':
                    setError(message.payload.error || 'Unknown error occurred');
                    break;
            }
        } catch (err) {
            console.error('Error parsing websocket message:', err);
            setError('Error processing server message');
        }
    }, []);

    // Rest of the component remains exactly the same as your current implementation
    // ...

};

const MetricCard = ({ label, value, format = 'number', icon }) => {
    const formatValue = (value, format) => {
        switch (format) {
            case 'duration':
                return `${Math.floor(value / 60)}:${String(value % 60).padStart(2, '0')}`;
            case 'percentage':
                return `${value.toFixed(1)}%`;
            default:
                return value.toString();
        }
    };

    return (
        <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
                {icon}
                <span className="text-sm text-gray-600">{label}</span>
            </div>
            <p className="text-2xl font-bold text-gray-900">
                {formatValue(value, format)}
            </p>
        </div>
    );
};

export default ActiveCallsMonitor;
