import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Settings from './Settings';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import ActiveCallsMonitor from './components/Analytics/ActiveCallsMonitor';
import AnalyticsDashboard from './components/Analytics/AnalyticsDashboard';
import RealTimeCallMonitor from './components/Analytics/RealTimeCallMonitor';
import { Phone, Users, Clock, AlertCircle, Settings as SettingsIcon, BarChart2 } from 'lucide-react';

// Dashboard Component
const Dashboard = () => {
  const [metrics, setMetrics] = React.useState({
    activeCalls: 0,
    totalCalls: 0,
    avgDuration: 0,
    errorRate: 0
  });
  const [clientId, setClientId] = React.useState(null);

  React.useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('/api/auth/check', {
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setClientId(data.user.clientId);
          fetchMetrics(data.user.clientId);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  const fetchMetrics = async (id) => {
    try {
      const response = await fetch(`/api/metrics/${id}`);
      if (response.ok) {
        const data = await response.json();
        setMetrics(data);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  return (
    <div className="p-6">
      <div className="bg-white rounded-lg shadow p-4 mb-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Welcome to Business Voice Connect</h1>
        <p className="text-gray-600">View your call statistics and system status below</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <MetricCard
          title="Active Calls"
          value={metrics.activeCalls}
          icon={<Phone className="w-5 h-5 text-blue-500" />}
        />
        <MetricCard
          title="Total Calls"
          value={metrics.totalCalls}
          icon={<Users className="w-5 h-5 text-green-500" />}
        />
        <MetricCard
          title="Avg Duration"
          value={`${metrics.avgDuration}m`}
          icon={<Clock className="w-5 h-5 text-purple-500" />}
        />
        <MetricCard
          title="Error Rate"
          value={`${metrics.errorRate}%`}
          icon={<AlertCircle className="w-5 h-5 text-red-500" />}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <QuickActionCard
          title="View Analytics"
          description="Detailed call analytics and metrics"
          link="/analytics"
        />
        <QuickActionCard
          title="Manage Settings"
          description="Configure system preferences"
          link="/settings"
        />
        <QuickActionCard
          title="View Clients"
          description="Manage client information"
          link="/clients"
        />
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="px-4 py-3 border-b border-gray-200">
          <h2 className="text-lg font-semibold">System Status</h2>
        </div>
        <div className="p-4">
          <StatusItem name="Voice Processing" status="operational" />
          <StatusItem name="Call Recording" status="operational" />
          <StatusItem name="Analytics Engine" status="operational" />
        </div>
      </div>
    </div>
  );
};

// Helper Components
const MetricCard = ({ title, value, icon }) => (
  <div className="bg-white rounded-lg shadow p-6">
    <div className="flex items-center justify-between mb-2">
      {icon}
      <span className="text-gray-500 text-sm">{title}</span>
    </div>
    <div className="text-2xl font-bold">{value}</div>
  </div>
);

const QuickActionCard = ({ title, description, link }) => (
  <div className="bg-white rounded-lg shadow p-6 hover:bg-gray-50 transition-colors">
    <Link to={link} className="block">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </Link>
  </div>
);

const StatusItem = ({ name, status }) => (
  <div className="flex items-center justify-between py-2">
    <span className="text-gray-700">{name}</span>
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
      status === 'operational' ? 'bg-green-100 text-green-800' :
      status === 'degraded' ? 'bg-yellow-100 text-yellow-800' :
      'bg-red-100 text-red-800'
    }`}>
      {status}
    </span>
  </div>
);

const Clients = () => <h2>Clients</h2>;
const Subscriptions = () => <h2>Subscriptions</h2>;

// Main Layout Component
const MainLayout = ({ children }) => {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('/api/auth/check', {
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleLogout = async () => {
    try {
      await fetch('/api/auth/logout', {
        method: 'POST',
        credentials: 'include'
      });
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header flex justify-between items-center px-6">
        <h1>Business Voice Connect</h1>
        {user && (
          <div className="flex items-center space-x-4">
            <span className="text-white">{user.email}</span>
            <button 
              onClick={handleLogout}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Logout
            </button>
          </div>
        )}
      </header>
      <div className="App-content">
        <nav className="App-sidebar">
          <ul>
            <li><Link to="/dashboard">Dashboard</Link></li>
            <li><Link to="/calls">Active Calls</Link></li>
            <li><Link to="/analytics">Analytics</Link></li>
            {user?.role === 'admin' && (
              <li><Link to="/admin/clients">Clients</Link></li>
            )}
            <li><Link to="/settings">Settings</Link></li>
          </ul>
        </nav>
        <main className="App-main">
          {children}
        </main>
      </div>
    </div>
  );
};

const Analytics = () => {
  const [clientId, setClientId] = React.useState(null);
  const [authToken, setAuthToken] = React.useState(null);

  React.useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('/api/auth/check', {
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setClientId(data.user.clientId);
          const cookies = document.cookie.split(';');
          const authCookie = cookies.find(cookie => cookie.trim().startsWith('auth_token='));
          if (authCookie) {
            setAuthToken(authCookie.split('=')[1].trim());
          }
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  if (!clientId || !authToken) {
    return (
      <div className="p-6">
        <div className="bg-blue-50 border border-blue-200 rounded-md p-4 text-blue-700">
          Loading analytics...
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <div className="bg-white rounded-lg shadow p-4 mb-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Analytics Dashboard</h1>
        <p className="text-gray-600">Real-time call monitoring and analytics</p>
      </div>
      <ActiveCallsMonitor clientId={clientId} authToken={authToken} />
      <RealTimeCallMonitor clientId={clientId} />
      <AnalyticsDashboard clientId={clientId} />
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        
        <Route path="/" element={
          <ProtectedRoute>
            <MainLayout>
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/calls" element={<ActiveCallsMonitor />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/settings" element={<Settings />} />
                
                {/* Admin Routes */}
                <Route path="/admin">
                  <Route path="clients" element={<Clients />} />
                  <Route path="settings" element={<Settings />} />
                </Route>
              </Routes>
            </MainLayout>
          </ProtectedRoute>
        } />
      </Routes>
    </Router>
  );
}

export default App;
